import styled from 'styled-components';

export const FooterContainer = styled.footer`
  background-color: black;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  position: relative;
`;

export const SocialMediaIcons = styled.div`
  display: flex;
  gap: 1rem;
  justify-content:center;
`;

export const LanguageSwitcherContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem; /* Add some space above the language buttons */
`;

export const LanguageButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 2rem; /* Adjust the width of the button */
  height: 2rem; /* Adjust the height of the button */
`;

export const LanguageButtonImage = styled.img`
  width: 2rem; /* Adjust the width of the flag image */
  height: auto;
`;

export const Logo = styled.img`
  width: 5rem;
  height: auto;
  padding-left: 2rem;
`;