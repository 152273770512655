import React from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import Logo from '../assets/img/dr-mario-arcila-logo.webp'
import Cirugias from '../components/Hardcoded/Cirugias'
    

function CirugiasPage() {
  return (
    <div>
        <Header logoSrc={Logo}/>
        <Cirugias />
        <Footer logoSrc={Logo}/>
    </div>
  );
}

export default CirugiasPage;
