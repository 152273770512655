import { React } from 'react';
import './Coolsculpting.css'
import coolPic from './img/coolsculpting-scaled.jpg';
function Abs() {

    return (
    <div>
        <section className="banner-title">
            <h1>CoolSculpting®</h1>
            <h3>Congela y elimina tus gorditos, sin cirugía ni incapacidad.</h3>
        </section>

        <section id="cooldescription" className="cool-description">
            <div className="image-box">
                <img src={coolPic} alt="Coolsculpting Description" />
            </div>
            <div className="description-box">
                <h1>¿Qué es CoolSculpting®?</h1>
                <p>CoolSculpting® es un tratamiento no invasivo para la reducción de adiposidad.†20 Es una forma innovadora de moldear el cuerpo en la que se congela la adiposidad no deseada.1,2,4 No requiere cirugía1,3 y el tiempo de inactividad previsto es mínimo.</p>
                <a href="#" className="description-btn">CONTÁCTENOS</a>
            </div>
        </section>

        <section id="coolvideo" className="cool-video">
        <iframe width="80%" height="100%" 
            src="https://www.youtube.com/embed/Y7aYm_uG03s" 
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; 
            autoplay; 
            clipboard-write;
             encrypted-media; 
             gyroscope; 
             picture-in-picture; 
             web-share" 
            allowfullscreen></iframe>
        </section>

        <section id="coolservice" className="cool-services">
            <div className="content-box">
                <h2>SERVICIOS</h2>
                <p>Este es un procedimiento no invasivo. Durante el tratamiento, se colocará un paño de gel y un aplicador en la zona a tratar. </p>
            </div>
            <div className="cool-services-image-box"></div>
        </section>

        <section id="coolform" className="cool-description" style={{minHeight:"900px"}}>
            <div className="image-box">
            </div>
            <div className="description-box">
                <h1>Déjanos tu nombre y número y te contactaremos por Whatsapp para tu valoración.</h1>
                <form className="form-contact">
                    <div className="form-wrapper">
                        <label for="name">Nombre*</label>
                        <input type="text" name="name" placeholder="Ingresa tu nombre" />
                    </div>
                    <div className="form-wrapper">
                        <label for="address">Apellido*</label>
                        <input type="text" name="address" placeholder="Ingresa tu dirección" />
                    </div>
                    
                    <div className="form-wrapper form-wrapper__contact">
                        <label for="name">Email*</label>
                        <input type="email" name="email" placeholder="Ingresa tu email" />
                    </div>
                    
                    <div className="form-wrapper form-wrapper__contact">
                        <label for="phone">Celular*</label>
                        <input type="text" name="phone" placeholder="Ingresa tu número de telefono" />
                    </div>

                    <a href="#" className="description-btn" type="submit">ENVIAR</a>
                </form>
            </div>
        </section>
    </div>
    );
}

export default Abs;