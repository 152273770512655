import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navbar, Logo, NavLinks, NavLink, HamburgerMenu, MobileMenu } from './HeaderStyles';

function Header({ logoSrc }) {
    const { t } = useTranslation(); // Use the hook to access the t function
    const [menuOpen, setMenuOpen] = useState(false);
  return (
    <Navbar>
      <Logo src={logoSrc} alt="Logo" />
      <HamburgerMenu onClick={() => setMenuOpen(!menuOpen)}>
        &#9776; {/* Unicode hamburger icon */}
      </HamburgerMenu>
      <NavLinks>
        <NavLink href="/#inicio">{t('navbar.home')}</NavLink>
        <NavLink href="/#abs">{t('navbar.abs')}</NavLink>
        <NavLink href="/#coolsculpting">{t('navbar.coolsculpting')}</NavLink>
        <NavLink href="/#antiage">{t('navbar.antiAge')}</NavLink>
        <NavLink href="/#inyectables">{t('navbar.injectables')}</NavLink>
        <NavLink href="/cirugias">{t('navbar.surgeries')}</NavLink>
        <NavLink href="/#contactenos">{t('navbar.contactUs')}</NavLink>
      </NavLinks>

      <MobileMenu open={menuOpen}>
        <NavLink href="/#inicio" onClick={() => setMenuOpen(false)}>{t('navbar.home')}</NavLink>
        <NavLink href="/#abs" onClick={() => setMenuOpen(false)}>{t('navbar.abs')}</NavLink>
        <NavLink href="/#coolsculpting" onClick={() => setMenuOpen(false)}>{t('navbar.coolsculpting')}</NavLink>
        <NavLink href="/#antiage" onClick={() => setMenuOpen(false)}>{t('navbar.antiAge')}</NavLink>
        <NavLink href="/#inyectables" onClick={() => setMenuOpen(false)}>{t('navbar.injectables')}</NavLink>
        <NavLink href="/cirugias" onClick={() => setMenuOpen(false)}>{t('navbar.surgeries')}</NavLink>
        <NavLink href="/#contactenos" onClick={() => setMenuOpen(false)}>{t('navbar.contactUs')}</NavLink>
        <button onClick={() => setMenuOpen(false)}>X</button> {/* Close button */}
      </MobileMenu>

    </Navbar>
  );
}

export default Header;
