import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainPage from './pages/MainPage';
import AbsPage from './pages/AbsPage';
import CoolsculptingPage from './pages/CoolsculptingPage';
import AntiagePage from './pages/AntiagePage';
import RejuvenecePage from './pages/RejuvenecePage';
import CirugiasPage from './pages/CirugiasPage';

function App() {
  return (
    <Router>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/abs" element={<AbsPage />} />
          <Route path="/coolsculpting" element={<CoolsculptingPage />} />
          <Route path='/antiage' element={<AntiagePage />} />
          <Route path='/rejuvenece' element={<RejuvenecePage />} />
          <Route path='/cirugias' element={<CirugiasPage />} />
        </Routes>
    </Router>
  );
}

export default App;