import { React } from 'react';
import './Antiage.css'
import ultherapyLogo from './img/antiage-brand.png';
function Antiage() {

    return (
    <div>
        <section className="antiage-banner-title">
            <h1>ANTI-AGE</h1>
        </section>

        <section className="antiage-banner-info">
            <p className="antiage-banner-info-description">
            Es un nuevo concepto de skin care avanzado que ofrece una amplia gama de tratamientos estéticos enfocados en el cuidado de la piel y el rejuvenecimiento facial de forma natural.
            <hr className="custom-line" />
            </p>
            
        </section>

        <section id="antiagedescription" className="antiage-description">
            <div className="antiage-column">
                <h1 className="antiage-column-title">¿Qué es Ultherapy?</h1>
                <p className="antiage-column-text">
                    El tratamiento Ultherapy es el único procedimiento de <a href="https://www.topdoctors.es/diccionario-medico/lifting-facial">lifting</a> no invasivo aprobado por la FDA. Es un tratamiento no quirúrgico que <a href='https://www.topdoctors.es/diccionario-medico/ultrasonidos-focalizados'>utiliza ultrasonidos</a> focalizados para estimular la formación de nuevo colágeno en las capas más profundas de la piel, 
                    proporcionándole un aspecto más natural, sin afectar a la superficie.
                </p>
                <div className='antiage-column-image'>
                    <img src={ultherapyLogo } alt="Ultherapy Logo"/>
                </div>
            </div>
            <div className="antiage-column-2"></div>
        </section>

        <section id="antiagevideo" className="antiage-video">
            <iframe
                src="https://www.youtube.com/embed/bSJ7hebde7Q" 
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; 
                autoplay; 
                clipboard-write;
                encrypted-media; 
                gyroscope; 
                picture-in-picture; 
                web-share" 
                allowfullscreen>
            </iframe>
        </section>
        
        <section id="antiage-explanation" className="antiage-explanation">
            <div className="antiage-explanation-image-box"></div>
                <div className="antiage-content-box">
                    <h2>¿Qué es la Radio Frecuencia fraccionada? </h2>
                    <p>La radiofrecuencia fraccionada es lo último en tecnología de radiofrecuencia. Se refiere a un tratamiento de estética no invasiva en donde se regeneran las fibras de colágeno de la piel, que son las que se encargan de mantener firmes los tejidos.</p>
                </div>
        </section>

        
    </div>
    );
}

export default Antiage;