import { React, useState, useEffect } from 'react';
import './Main.css'
import coolMachine from './img/maquina.png';

function Main() {

    const [currentReview, setCurrentReview] = useState(0);
    const [isVisible, setIsVisible] = useState(true);

    const reviews = [
        {
            name: 'Maria Juliana',
            text: '“No me haría el Botox con nadie más… Es casi un arte, hacer que se vea natural. Prefiero ir a la segura y hacermelo con Mario.”',
        },
        {
            name: 'Andrea',
            text: '“Inicialmente tenia miedo de operarme, pero cuando fuí al consultorio y conocí al doctor me tranquilice mucho. Ahora estoy super contenta porque mis resultados se ven muy naturales y pienso que eso es lo más importante”',
        },
        // Add more reviews as needed
    ];

    const nextReview = () => {
        setCurrentReview((prevReview) => (prevReview + 1) % reviews.length);
    };

    const prevReview = () => {
        setCurrentReview((prevReview) => (prevReview - 1 + reviews.length) % reviews.length);
    };

    useEffect(() => {
        setIsVisible(false);
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, 300); 

        return () => clearTimeout(timer);
    }, [currentReview]);

    return (
    <div>
        <section id="inicio" className="main-banner">
            <picture className="main-banner-bg">
                <source srcSet="./img/main-banner-bg-desktop.webp" media="(min-width: 1024px)" />
                <img src="./img/main-banner-bg-mobile.webp" width="100%" height="100%" alt="main-banner" />
            </picture>
            <article className="main-banner-info">
                <h1>DR. <br /> MARIO  <br /> ARCILA</h1>
                <h2>CIRUJANO PLÁSTICO</h2>
                <a href="#form">CONTÁCTENOS</a>
            </article>
        </section>

        <section className="banner-info">
            <p className="description">
                20.000 personas han confiado en el profesionalismo y calidad de resultados del Cirujano Plástico
            </p>
        </section>

        <section id="abs" className="second-banner"> 
            <div className="container">
                <picture className="img-title">
                    <source srcset="./img/ABS.png" media="(min-width: 1024px)" />
                    <img src="./img/ABS-title-mobile.webp" width="100%" height="100%" alt="abs"/>
                </picture>
                <p className="description">ABS technology es lipoescultura de alta definición, una avanzada técnica quirúrgica de moldeo corporal que permite crear una apariencia atletica esculpida, tanto en hombres como en mujeres.</p>
                <a href="/abs" className="btn-show-more">VER MÁS</a>
            </div>
            
        </section>

        <section id="coolsculpting" className="third-banner">
            <article className="first-wrapper">
                <img src="./img/coolsculpting-image.webp" width="100%" height="100%" alt="coolsculpting" />
            </article> 
            <img className="machine" src="./img/maquina.png" alt="maquina coolsculping" />    
            <article className="second-wrapper">
                <img className="bg-pattern" src="./img/coolsculping-bg-background.jpg" width="100%" height="100%" alt="coolsculpting info" />
                <img className="title" src="./img/coolsculpting-title.png" alt="Coolsculpting Title" width="100%" height="100%" />
                <img className="description" src="./img/coolsculping-text.png" alt=" Coolsculpting Text" />
                <a className="cta-btn" href="/coolsculpting">VER MÁS</a>
            </article>
        </section>

        <section id="antiage" className="antiage-banner">
            <article className="first-wrapper"> 
                <img src="./img/antiage-first-image.jpg" width="100%" height="100%" alt="antiage"/>
                <img className="antiage-brand" src="./img/antiage-brand.png" alt="antiage brand" />
            </article>
            <article className="second-wrapper">
                <img className="bg-pattern" src="./img/antiage-bg-banner.jpg" alt="antiage-background" width="100%" height="100%" />
                <p className="antiage-description">
                    Es un nuevo concepto de skin care avanzado que ofrece una amplia gama de tratamientos estéticos enfocados en el cuidado de la piel y el rejuvenecimiento facial de forma natural. 
                </p>
                <a className="antiage-cta" href='/antiage'>VER MÁS</a>
            </article>
        </section>

        <section id="inyectables" className="rejuvenece-banner">
            {/* <picture className="first-image">
                <img src="./img/rejuvenece-bg-mobile.webp" alt="rejuvenece banner" width="100%" height="100%"/>
            </picture> */}
            
            <picture className="second-image">
                <source srcset="./img/rejuvenece-info.jpg" media="(min-width: 1024px)" />
                <img src="./img/rejuvenece-info-mobile.webp" alt="info" width="100%" height="100%"/>
            </picture>
            <a className="rejuvenece-btn" href='/rejuvenece'>VER MÁS</a>
        </section>
        
        <section className="reviews">
            <h3>{reviews[currentReview].name}</h3>
            <hr />
            <p className={isVisible ? 'visible' : ''}>{reviews[currentReview].text}</p>

            <div className="dots">
            {reviews.map((_, idx) => (
                <span
                key={idx}
                className={`dot ${currentReview === idx ? 'active' : ''}`}
                onClick={() => setCurrentReview(idx)}
                ></span>
            ))}
            </div>

            <button onClick={prevReview}>&lsaquo;</button>
            <button onClick={nextReview}>&rsaquo;</button>
        </section>
    
        <section className="map">

        </section>

        <section id="contactenos" className="form">
            <form className="form-contact">
                <div className="form-wrapper">
                    <label for="name">Nombre*</label>
                    <input type="text" name="name" placeholder="Ingresa tu nombre" />
                </div>
                <div className="form-wrapper">
                    <label for="address">Direccion</label>
                    <input type="text" name="address" placeholder="Ingresa tu dirección" />
                </div>
                
                <div className="form-wrapper form-wrapper__contact">
                    <label for="name">Email*</label>
                    <input type="email" name="email" placeholder="Ingresa tu email" />
                </div>
                
                <div className="form-wrapper form-wrapper__contact">
                    <label for="phone">Teléfono</label>
                    <input type="text" name="phone" placeholder="Ingresa tu número de telefono" />
                </div>
                
                <div className="form-wrapper">
                    <label for="subject">Asunto</label>
                    <input type="text" name="subject" placeholder="Ingresa el asunto" />
                </div>
                
                <div className="form-wrapper">
                    <label for="message">Mensaje</label>
                    <textarea name="message"cols="30" rows="10" placeholder="Escribe tu mensaje"></textarea>
                </div>

                <button className="form-cta" type="submit">ENVIAR</button>
            </form>
        </section>
    </div>
    );
}

export default Main;