import styled from 'styled-components';

export const Navbar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 5%;
  position: sticky;
  top: 0;
  background-color: black;
  z-index: 1000;
`;

export const Logo = styled.img`
  width: 6rem; /* 150px -> 10rem assuming root font-size is 15px */
  height: auto;
  @media (max-width: 768px) {
    height: auto;
    width: 3rem; /* 50px -> 3rem assuming root font-size is 15px */
  }
`;

export const NavLinks = styled.div`
  display: flex;
  gap: 2rem;

  @media (max-width: 960px) {
    display: none;
  }
`;

export const NavLink = styled.a`
  color: white;
  text-decoration: none;
  font-size: 1rem;
  display: block;
  padding: 0.5rem 0;
  &:hover {
    text-decoration: underline;
  }
`;

export const HamburgerMenu = styled.div`
  display: none;
  cursor: pointer;
  color:white;
  font-size:2rem;
  @media (max-width: 960px) {
    display: block;
  }
`;

export const MobileMenu = styled.div`
  display: none;
  width: 13rem; /* 200px -> 13rem assuming root font-size is 15px */
  @media (max-width: 960px) {
    display: ${props => (props.open ? 'block' : 'none')};
    position: absolute;
    top: 4rem; /* 60px -> 4rem assuming root font-size is 15px */
    right: 5%;
    background-color: black;
    padding: 1rem;
  }
`;
