import React from 'react';
import styled from 'styled-components';
import fbLogo from '../../assets/img/fb-logo.webp';
import igLogo from '../../assets/img/ig-logo.webp';
import ytLogo from '../../assets/img/yt-logo.webp';

const IconLink = styled.a`

  img {
    width: 2rem;
    height: auto;
  }
`;

function SocialNetworkIcons({ links }) {

    const icons = [
        { src: igLogo, alt: 'Instagram' },
        { src: fbLogo, alt: 'Facebook' },
        { src: ytLogo, alt: 'YouTube' },
      ];

  return (
    <>
      {icons.map((icon, index) => (
        <IconLink href={links[index]} target="_blank" rel="noopener noreferrer" key={icon.alt}>
          <img src={icon.src} alt={icon.alt} />
        </IconLink>
      ))}
    </>
  );
}

export default SocialNetworkIcons;
