import React from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import Logo from '../assets/img/dr-mario-arcila-logo.webp'
import Abs from '../components/Hardcoded/Abs'
    

function AbsPage() {
  return (
    <div>
        <Header logoSrc={Logo}/>
        <Abs />
        <Footer logoSrc={Logo}/>
    </div>
  );
}

export default AbsPage;
