import React from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import Logo from '../assets/img/dr-mario-arcila-logo.webp'
import Antiage from '../components/Hardcoded/Antiage'
    

function AntiagePage() {
  return (
    <div>
        <Header logoSrc={Logo}/>
        <Antiage />
        <Footer logoSrc={Logo}/>
    </div>
  );
}

export default AntiagePage;
