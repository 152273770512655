import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from 'i18next-http-backend';

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    lng: "es", // default language
    fallbackLng: "es",
    backend: {
      loadPath: '/locales/{{lng}}/translation.json', // path to the translation files
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
