
import React from 'react';
import { FooterContainer, SocialMediaIcons, LanguageSwitcherContainer, Logo } from './FooterStyles';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher'
import SocialNetworkIcons from '../shared/SocialNetworkIcons'; // Import the component

function Footer({ logoSrc }) {
    const { t } = useTranslation();

    const socialLinks = [ // Define your social media links here
    'https://www.instagram.com/dr.marioarcila/',
    'https://facebook.com',
    'https://www.youtube.com/@dr.marioarcila7464',
  ];

  return (
    
    <FooterContainer>
        <div>

        </div>
      <div>
      <SocialMediaIcons>
        <SocialNetworkIcons links={socialLinks} /> {/* Pass the links here */}
      </SocialMediaIcons>
        <p>{t('footer.copyright')}</p>
            <LanguageSwitcherContainer>
            <LanguageSwitcher />
        </LanguageSwitcherContainer>
      </div>
      <Logo src={logoSrc} alt="Logo" />
    </FooterContainer>
  );
}

export default Footer;
