import { React } from 'react';
import './Abs.css'
import absLogo from './img/ABSLOGO.webp';
import absHombre1 from './img/abs_hom1.jpg';
import absHombre2 from './img/abs_hom2.jpg';
import absMujer1 from './img/abs_mujer1.jpg';
import absMujer2 from './img/abs_mujer2.jpg';
function Abs() {

    return (
    <div>
        <section className="abs-banner-title">
            <h1>ABS TECHNOLOGY</h1>
        </section>

        
        <section id="absdescription" className="abs-description">
            <div className="column">
                <img src={absLogo} alt="abstechnology" className="column-image" />
                <h1 className="column-title">Lipo escultura de alta definición</h1>
                <p className="column-text">ABS Technology es lipoescultura de alta definición, una avanzada técnica quirúrgica de moldeo corporal que permite crear una apariencia atlética esculpida. tanto en hombres como en mujeres; con la extracción precisa de la grasa superficial y de la grasa profunda localizada alrededor de los músculos, con el fin de resaltar la musculatura subyacente.</p>
                <button className="column-button">CONTÁCTENOS</button>
            </div>
            <div className="column-2" style={{ backgroundImage: "url('./img/ABS1.jpg')", width: "100%" }}>
            </div>
        </section>

        <section id="absvideo" className="abs-video">
        <iframe width="70%" height="100%" 
            src="https://www.youtube.com/embed/2oDtEcQzvf8?si=7GLnKZQo5XwAJxFQ" 
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; 
            autoplay; 
            clipboard-write;
             encrypted-media; 
             gyroscope; 
             picture-in-picture; 
             web-share" 
            allowfullscreen></iframe>
        </section>
        <section id="absbeforeafter" className="abs-before-after">
            <div className="abs-card">
                <div className="labels">
                    <div className="label">Before</div>
                    <div className="label">After</div>
                </div>
                <div className="separator"></div>
                <div className="images">
                    <img src={absHombre1} alt="Before and After 1" />
                </div>
            </div>
            <div className="abs-card">
                <div className="labels">
                    <div className="label">Before</div>
                    <div className="label">After</div>
                </div>
                <div className="separator"></div>
                <div className="images">
                    <img src={absHombre2} alt="Before and After 1" />
                </div>
            </div>
            <div className="abs-card">
                <div className="labels">
                    <div className="label">Before</div>
                    <div className="label">After</div>
                </div>
                <div className="separator"></div>
                <div className="images">
                    <img src={absMujer1} alt="Before and After 1" />
                </div>
            </div>
            <div className="abs-card">
                <div className="labels">
                    <div className="label">Before</div>
                    <div className="label">After</div>
                </div>
                <div className="separator"></div>
                <div className="images">
                    <img src={absMujer2} alt="Before and After 1" />
                </div>
            </div>
        </section>

        <section id="form" className="form">
            <form className="form-contact">
                <div className="form-wrapper">
                    <label for="name">Nombre*</label>
                    <input type="text" name="name" placeholder="Ingresa tu nombre" />
                </div>
                <div className="form-wrapper">
                    <label for="address">Direccion</label>
                    <input type="text" name="address" placeholder="Ingresa tu dirección" />
                </div>
                
                <div className="form-wrapper form-wrapper__contact">
                    <label for="name">Email*</label>
                    <input type="email" name="email" placeholder="Ingresa tu email" />
                </div>
                
                <div className="form-wrapper form-wrapper__contact">
                    <label for="phone">Teléfono</label>
                    <input type="text" name="phone" placeholder="Ingresa tu número de telefono" />
                </div>
                
                <div className="form-wrapper">
                    <label for="subject">Asunto</label>
                    <input type="text" name="subject" placeholder="Ingresa el asunto" />
                </div>
                
                <div className="form-wrapper">
                    <label for="message">Mensaje</label>
                    <textarea name="message"cols="30" rows="10" placeholder="Escribe tu mensaje"></textarea>
                </div>

                <button className="form-cta" type="submit">ENVIAR</button>
            </form>
        </section>
    </div>
    );
}

export default Abs;