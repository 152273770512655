import { React, useState, useEffect } from 'react';
import './Rejuvenece.css'
import rejuvenecePhoto from './img/inyectables_MARIO_ARCILA2.jpg';
function Rejuvenece() {

    const [currentReview, setCurrentReview] = useState(0);
    const [isVisible, setIsVisible] = useState(true);

    const reviews = [
        {
            name: 'Botox',
            text: 'El Botox es un tratamiento para reducir las arrugas de expresión, inhibiendo la contracción parcial de los músculos. En otras palabras, relaja los músculos para que no se contraigan tan fuertemente. Mejora el aspecto de las caras de aspecto cansado.  El Botox es particularmente útil cuando se usa preventivamente ya que la arruga aún no está marcada y ayuda a evitar que se vuelva más evidente.  Las áreas más comúnmente tratadas son la frente, entre las cejas y las patas de gallo.',
        },
        {
            name: 'Ácido Hialurónico',
            text: 'Los rellenos volumétricos se utilizan para rellenar áreas deprimidas o huecas de la cara que necesitan volumen. Las zonas más comunes para recibir rellenos volumétricos son las mejillas y el mentón. También se pueden utilizar para realizar cambios estructurales y estiramientos faciales no quirúrgicos.',
        },
        // Add more reviews as needed
    ];

    const nextReview = () => {
        setCurrentReview((prevReview) => (prevReview + 1) % reviews.length);
    };

    const prevReview = () => {
        setCurrentReview((prevReview) => (prevReview - 1 + reviews.length) % reviews.length);
    };

    useEffect(() => {
        setIsVisible(false);
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, 5); 

        return () => clearTimeout(timer);
    }, [currentReview]);

    return (
    <div>
        <section className="rejuvenece-banner-title">
            <h1>INYECTABLES</h1>
        </section>

        <section id="rejuvenecedescription" className="rejuvenece-description">
            <div className="rejuvenece-image-box">
                <img src={rejuvenecePhoto} alt="Rejuvenece description" />
            </div>
            <div className="rejuvenece-description-box">
                <h1>Rejuvenece tus arrugas de expresión o voluminosa tus labios, en manos de un experto</h1>
                <p>Para el éxito del procedimiento, es esencial que trabaje con un profesional que tenga un largo historial de proporcionar a los pacientes altos niveles de satisfacción. Los resultados deben ser sutiles pero poderosos, por lo se debe tener la habilidad y el juicio para tomar las decisiones correctas al inyectar para obtener resultados óptimos.</p>
            </div>
        </section>

        <section className="banner-info">
            <p className="description">
                Líder de inyectables en Colombia.
            </p>
        </section>

        <section className="rejuvenece-reviews">
            <h3>{reviews[currentReview].name}</h3>
            <hr />
            <p className={isVisible ? 'visible' : ''}>{reviews[currentReview].text}</p>

            <div className="rejuvenece-dots">
            {reviews.map((_, idx) => (
                <span
                key={idx}
                className={`rejuvenece-dot ${currentReview === idx ? 'active' : ''}`}
                onClick={() => setCurrentReview(idx)}
                ></span>
            ))}
            </div>

            <button className="rejuvenece-review-button" onClick={prevReview}>&larr;</button>
            <button className="rejuvenece-review-button" onClick={nextReview}>&rarr;</button>
        </section>

        <section id="rejuveneceform" className="rejuvenece-description" style={{ minHeight:"90vh"}}>
            <div className="rejuvenece-image-box">
            </div>
            <div className="rejuvenece-description-box">
                <h1>Déjanos tu nombre y número y te contactaremos por Whatsapp para tu valoración.</h1>
                <form className="form-contact">
                    <div className="form-wrapper">
                        <label for="name">Nombre*</label>
                        <input type="text" name="name" placeholder="Ingresa tu nombre" />
                    </div>
                    <div className="form-wrapper">
                        <label for="address">Apellido*</label>
                        <input type="text" name="address" placeholder="Ingresa tu dirección" />
                    </div>
                    
                    <div className="form-wrapper form-wrapper__contact">
                        <label for="name">Email*</label>
                        <input type="email" name="email" placeholder="Ingresa tu email" />
                    </div>
                    
                    <div className="form-wrapper form-wrapper__contact">
                        <label for="phone">Celular*</label>
                        <input type="text" name="phone" placeholder="Ingresa tu número de telefono" />
                    </div>

                    <a href="#" className="rejuvenece-description-btn" type="submit">ENVIAR</a>
                </form>
            </div>
        </section>

    </div>
    );
}

export default Rejuvenece;