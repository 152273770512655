import React from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageButton, LanguageButtonImage } from '../Footer/FooterStyles';
import spanishButton from '../../assets/img/btn-espanol.png';
import englishButton from '../../assets/img/btn-usa.png';

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  return (
    <div>
      <LanguageButton onClick={() => i18n.changeLanguage('en')}>
      <LanguageButtonImage src={englishButton} alt="English"/>
      </LanguageButton>
      <LanguageButton onClick={() => i18n.changeLanguage('es')}>
        <LanguageButtonImage  src={spanishButton} alt="Español" />
      </LanguageButton>
    </div>
  );
}

export default LanguageSwitcher;
