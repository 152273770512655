import { React } from 'react';
import './Cirugias.css'
import ba1 from './img/Cirugia_MA1.webp';
import ba2 from './img/Cirugia_MA2.webp';
import ba3 from './img/Cirugia_MA3.webp';
import ba4 from './img/Cirugia_MA4.webp';
import ba5 from './img/Facial_MA5.webp';
import ba6 from './img/Facial_MA6.webp';
import marioArcilaPhoto from './img/MA.jpg';
function Abs() {

    return (
    <div>
        <section className="cirugias-banner-title">
            <h1>CIRUGIAS</h1>
        </section>

        <section id="cirugiasdescription" className="rejuvenece-description" style={{backgroundColor:"#44444B"}}>
            <div className="rejuvenece-image-box">
                <img src={marioArcilaPhoto} alt="Mario Arcila" />
            </div>
            <div className="rejuvenece-description-box">
                <h1>20,000 Personas han confiado en el profesionalismo y calidad de resultados del Cirujano Plástico Dr. Mario Arcila.</h1>
            </div>
        </section>

        <section id="cirugiasbeforeafter" className="cirugias-before-after">
            <div className="cirugias-card">
                <div className="labels">
                    <div className="label">Before</div>
                    <div className="label">After</div>
                </div>
                <div className="separator"></div>
                <div className="images">
                    <img src={ba1} alt="Before and After 1" />
                </div>
            </div>
            <div className="cirugias-card">
                <div className="labels">
                    <div className="label">Before</div>
                    <div className="label">After</div>
                </div>
                <div className="separator"></div>
                <div className="images">
                    <img src={ba2} alt="Before and After 2" />
                </div>
            </div>
            <div className="cirugias-card">
                <div className="labels">
                    <div className="label">Before</div>
                    <div className="label">After</div>
                </div>
                <div className="separator"></div>
                <div className="images">
                    <img src={ba3} alt="Before and After 3" />
                </div>
            </div>
            <div className="cirugias-card">
                <div className="labels">
                    <div className="label">Before</div>
                    <div className="label">After</div>
                </div>
                <div className="separator"></div>
                <div className="images">
                    <img src={ba4} alt="Before and After 4" />
                </div>
            </div>
            <div className="cirugias-card">
                <div className="labels">
                    <div className="label">Before</div>
                    <div className="label">After</div>
                </div>
                <div className="separator"></div>
                <div className="images">
                    <img src={ba5} alt="Before and After 5" />
                </div>
            </div>
            <div className="cirugias-card">
                <div className="labels">
                    <div className="label">Before</div>
                    <div className="label">After</div>
                </div>
                <div className="separator"></div>
                <div className="images">
                    <img src={ba6} alt="Before and After 6" />
                </div>
            </div>
        </section>
    </div>
    );
}

export default Abs;